import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class SangreAzul extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Sangre Azul'}
          description={'Prípadová štúdia'}
          text={'Pre prestížnu kliniku Sangre Azul sme vytvorili modernú firemnú webstránku, spolu s profesionálnym logom a firemnou identitou.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'logo & branding', link: '/nase-prace/grafika/logo-branding/sangre-azul-branding' },
            { text: 'ux & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (wordpress)', link: '/nase-prace/webstranky/firemne/sangre-azul' },
          ]} />

        <AboutProject
          text={'Spolupráca s Sangre Azul prebiehala nasledovne:'}
          list={[
            {
              title: 'Ako to prebiehalo',
              text: 'Poďme sa spolu pozrieť na jednotlivé etapy vývoja projektu. Každý jeden projekt a klient je individuálny, podľa toho sa líši aj rozsah a detail prác na projekte. O momentálnej fáze tvorby je klient vždy informovaný a poskytuje nám svoj feedback.',
            },
            {
              title: 'Zadanie',
              text: 'Klient nám poskytol zadanie, ktoré sme si spolu odkonzultovali a spracovali do projektovej dokumentácie, ktorou sa následne riadil celý projekt. Niektorí klienti majú vlastnú špecifikáciu, iným ju spracujeme my na základe požiadaviek. Pri rozsiahlejších projektoch sa navrhuje celá architektúra, rozpisujú sa detailne všetky funkcie a kreslia sa use-case modely.',
            },
            {
              title: 'Riešenie',
              text: 'Od začiatku prác na projekte sa vyčlení menší tím, ktorý sa venuje práve danému projektu. Snažíme sa pracovať non-stop, aby už po krátkej dobe videl klient nejakú ukážku alebo prototyp a videli sme, či ideme správnou cestou. Ďalej projekt konzultujeme a posielame updaty, až kým sa nedopracujeme k výsledku, s ktorým sme aj my aj klient spokojný.',
            },
            {
              title: 'Výsledok',
              text: 'Na konci developmentu posielame klientovi beta verziu, kde si vie skontrolovať celý design a všetky funkcionality. Na tejto verzii rovnako testujeme aj my a optimalizujeme posledné detaily pred spustením projektu live.',
            }
          ]}
        />

        <ProjectShortDescription
          title={'Firemná identita'}
          description={'Pochopenie projektu, jeho zámeru a odborný prístup sú úspechom k plnohodnotnej firemnej identite.'}
          text={'Po konzultáciach s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal nový projekt prezentovať nie len designom, ale aj textami, fotkami a inými komunikačno-vizuálnymi kanálmi.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#3e69b2', tintColorCode: '#6587c1', nameColor: '#FFFFFF', name: 'Tufts Blue' },
          { code: '#7a7e7f', tintColorCode: '#959899', nameColor: '#FFFFFF', name: 'Gray' },
          { code: '#19191d', tintColorCode: '#47474a', nameColor: '#FFFFFF', name: 'Black' },
          { code: '#ffffff', tintColorCode: '#ffffff', nameColor: '#6B7B8A', name: 'White' },
        ]} />

        <FontShowcase
          fontName={'Lido'}
          text={'Lido is suitable for all periodicals wishing to abandon inconspicuously the hideous system typefaces with their even more hideous accents and to change over to the contemporary level of graphic design. It is also most convenient for everyday work in text editors and office applications. It has a fairly large x-height of lower case letters, shortened serifs and simplified endings of rounded strokes. This is typical of the typefaces designed for use in small sizes. Our typeface, however, can sustain enlargement even to the size appropriate for a poster, an information table or a billboard, as it is not trite and at the same time is moderate in expression.'}
          isFullWidth={false}
          fontExampleSvg={'/images/svg/fonts/lido.svg'}
        />

        <Reveal>
          <div className={'container p-0 mb-big'}>
            <Img alt={this.constructor.name} className={'mb-5'} fluid={data.corporateIdentity.childImageSharp.fluid} />
          </div>
        </Reveal>

        <PagesShowcase
          title={'UX & Moderný web design'}
          description={'Nová profesionálna tvár Vášho webu'}
          text={'Nový design stránky Sangre Azul zahŕňa všetko, čo by mala profesionálna stránka spĺňať. Jednoduchosť, krása a jedinečnosť sú len jednou časťou, ale tou hlavnou je samotný obsah stránky, ktorý musí byť stredobodom pre uživateľa, aby vypovedal to, čo je potrebné.'}
          projectDetail={'/nase-prace/webstranky/firemne/sangre-azul'}
          pages={[
            {
              name: 'Hlavná stránka',
              image: data.pageOne,
            },
            {
              name: 'Menu',
              image: data.pageTwo,
            },
            {
              name: 'Referencie',
              image: data.pageThree,
            },
            {
              name: 'O nás',
              image: data.pageFour,
            },
            {
              name: 'Články',
              image: data.pageFive,
            },
            {
              name: 'Novinky',
              image: data.pageSix,
            },
            {
              name: 'Kontakt',
              image: data.pageSeven,
            },
            {
              name: 'Objednávka',
              image: data.pageEight,
            },
          ]} />

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="mobilnetelefony" />
          </div>
        </Reveal>

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.sangreazul.sk" target='_blank' className='link-secondary'>www.sangreazul.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Tatry golf residence park'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/firemne/tatry-golf-residence-park/'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project: markdownRemark(fields: {slug: {regex: "/sangre_azul/"}}) {
      frontmatter {
        banner_url
          screens {
              href {
                  childImageSharp {
                      fluid(quality: 100, maxWidth: 1920) {
                          ...GatsbyImageSharpFluid_withWebp
                          presentationWidth
                      }
                  }
              }
              alt
          }
      }
  }
    landingImage: file(relativePath: { eq: "case-studies/SangreAzul_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/home_and.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/home_menu_and.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/pacienti_and.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFour: file(relativePath: { eq: "case-studies/sangre_azul_and.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFive: file(relativePath: { eq: "case-studies/vypic_clankov_and.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSix: file(relativePath: { eq: "case-studies/5_sangre_web.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSeven: file(relativePath: { eq: "case-studies/6_sangre_web.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageEight: file(relativePath: { eq: "case-studies/kontakt_and.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/SangreAzul_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    corporateIdentity: file(relativePath: { eq: "case-studies/SangreAzul_CorporateIdentity.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`
export default SangreAzul
